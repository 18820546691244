import React, { useEffect } from "react";
import Cookies from "js-cookie";

import { useDispatch, useSelector } from "react-redux";
import { updateLayout } from "../store/slices/layouts";
import {
  ADMIN_ACCOUNT_DATA,
  FORGET_PASSWORD_LAYOUT,
  MAIN_LAYOUT,
} from "../utils/constants";
import { updateCredentials, useAdminConnectMutation } from "../store";
import Alert from "./common/Alert";

export default function AdminLayout() {
  const dispatch = useDispatch();
  const [adminConnect, resutls] = useAdminConnectMutation();

  const { username, password } = useSelector(({ adminCredentials }) => {
    return adminCredentials;
  });

  useEffect(() => {
    if (resutls.data?.token) {
      Cookies.set("token", resutls.data.token, {
        domain: ".scholine.com",
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("account", resutls.data.role, {
        domain: ".scholine.com",
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("name", resutls.data.fullName, {
        domain: ".scholine.com",
        secure: true,
        sameSite: "Strict",
      });
      Cookies.set("domain", resutls.data.url, {
        domain: ".scholine.com",
        secure: true,
        sameSite: "Strict",
      });

      const url = process.env.REACT_APP_DEV_URL
        ? `http://127.0.0.1:4000/admin/annexes`
        : `${resutls.data.url}/admin/annexes`;

      window.location.href = url;
    }
  }, [resutls]);

  const onLayoutChange = (layout) => {
    dispatch(updateLayout({ layout }));
  };

  const handleUsernameChange = (event) => {
    dispatch(
      updateCredentials({ state: event.target.name, value: event.target.value })
    );
  };

  const handlePasswordChange = (event) => {
    dispatch(
      updateCredentials({ state: event.target.name, value: event.target.value })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    adminConnect({ username, password });
  };

  return (
    <div className="container flex flex-col space-y-6 mx-auto md:flex-row md:space-y-0 md:space-x-12">
      <div className="md:block md:w-1/2">
        <img src={ADMIN_ACCOUNT_DATA.image} alt="" />
      </div>

      <div className="flex flex-col items-center space-y-3 md:w-1/2">
        <div>
          <span
            onClick={() => onLayoutChange(MAIN_LAYOUT)}
            onChange={handleUsernameChange}
            className="text-brightRed cursor-pointer back-btn hover:text-darkGray animate-pulse"
          >
            Retour au mode de connexion ?
          </span>
        </div>

        <div className="max-w-sm flex flex-col mx-auto py-6 px-4 rounded-3xl bg-brightRedLight space-y-3 shadow-2xl">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3">
              <input
                type="text"
                name="username"
                placeholder="Nom d'utilisateur"
                className="px-5 py-2 rounded-full focuse:outline-none"
                value={username}
                onChange={handleUsernameChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Mot de passe"
                className="px-5 py-2 rounded-full focuse:outline-none md:"
                value={password}
                onChange={handlePasswordChange}
              />

              {resutls.isLoading ? (
                "waiting..."
              ) : (
                <button
                  type="submit"
                  className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-gray-900"
                >
                  Connecter
                </button>
              )}
            </div>
          </form>
          {resutls.error && <Alert data={resutls.error.data.errors} />}
          <div className="py-6 px-4 rounded-3xl bg-white hidden">
            <ul className="list-disc"></ul>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-0">
          <span
            onClick={() => onLayoutChange(FORGET_PASSWORD_LAYOUT)}
            className="hover:text-brightRed cursor-pointer switch-login"
          >
            Mot de passe oublié?
          </span>
        </div>
      </div>
    </div>
  );
}
